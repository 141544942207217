import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//  Import Header and Footer
import UpperHead from "./includes/UpperHead";
import Footer from "./includes/Footer";

// Import Google Analytics
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

//  Importing pages
import Home from "./pages/Home";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";
import Property from "./pages/Property";

import ScrollToTop from "./components/ScrollToTop";

const trackingId = "UA-131130661-9";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update  users current page
  ReactGA.pageview(location.pathname); // Reacord a pageview for the given page
});

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router basename={process.env.PUBLIC_URL}>
          <ScrollToTop />
          <UpperHead />
          <Switch>
            <Route exact path="/" component={Home} />
            <Redirect from="/index.html" to="/" />
            <Route exact path="/About" component={About} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/terms-and-conditions" component={Terms} />
            <Route exact path="/Contact" component={Contact} />
            <Route exact path="/Property/:PropertyId" component={Property} />
            <Route
              render={() => (
                <h1 className="text-center">
                  WE ARE SORRY.
                  <br />
                  BUT THE PAGE YOU REQUESTED WAS NOT FOUND
                </h1>
              )}
            />
          </Switch>
          <Footer />
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
