import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function Terms() {
  return (
    <React.Fragment>
      <Container className="bg-text sm-width">
        <Row>
          <Col className="text-left">
            <h1> Terms and Conditions</h1>
            <p>
              Please read these TERMS AND CONDITIONS OF USE carefully. By using
              forrentca.com website or a related website (the "Site") belonging
              to Marco Management, LLC. or one of its subsidiaries, you hereby
              agree to these TERMS AND CONDITIONS OF USE. If you do not agree to
              these TERMS AND CONDITIONS OF USE, please do not use this site.
            </p>
            <p>
              Marco Management reserves the right at its discretion to change
              these TERMS AND CONDITIONS OF USE at any time without notice. Your
              continued use of this website after the change of TERMS AND
              CONDITIONS OF USE shall constitute your acceptance to agree to any
              such changes.
            </p>
            <p>
              Using our software systems, websites, applications, you consent to
              the following: You acknowledge that either by submitting messages
              or uploading i) data ii) documents iii) pictures iv) other types
              of attachments and data into this software / webpage, your
              submissions will be recorded and you expressly consent to and
              grant Marco Management, LLC. the right to record your activity and
              a perpetual, royalty-free, irrevocable, non-exclusive right and
              license to use, reproduce, modify, adapt, publish, translate,
              create derivative works from and distribute such materials or
              incorporate such materials into any form. If you do not consent,
              you can talk to a live agent at{" "}
              <a href="tel:+18669244732">(866) 924-4732</a> and you will have
              access to the same financial products.
            </p>
            <h3>
              <strong>COPYRIGHT</strong>
            </h3>
            <p>
              All information displayed, transmitted or carried on the Site
              (including, but not limited to, text, photographs, news articles,
              opinions, reviews, trademarks, service marks and the like,
              collectively the "Content") is protected by international
              copyright and other intellectual property laws. The Content is
              owned by Marco Management, its affiliates or third party
              licensors. Any use of the Content, including but not limited to,
              modification, distribution, reproduction, republication,
              transmission, posting, reposting, transfer, uploading, duplication
              or selling without the prior written permission of Marco
              Management is strictly prohibited. You may make a single copy of
              the Content displayed on the Site for your personal,
              informational, noncommercial offline use only, provided the
              Content is not modified in any way and all copyright and other
              notice on any copy are retained. The use of any such Content on
              any other website or network computer environment is prohibited.
            </p>
            <h3>
              <strong>DISCLAIMER</strong>
            </h3>
            <p>
              Marco Management disclaims all warranties, express or implied,
              including but not limited to, implied warranties of
              merchantability or fitness for a particular purpose. Marco
              Management does not represent or warrant that materials on the
              Site are accurate, complete, reliable, current, or error-free.
              Marco Management does not represent or warrant that the Site or
              its server(s) are free of viruses or other harmful components.
            </p>
            <h3>
              <strong>YOUR USE OF THE SITE AND CONTENT</strong>
            </h3>
            <p>
              You must access and use our Site and Content only for legal,
              authorized, and acceptable purposes. You will not use (or assist
              others in using) our Site or Content in ways that: (a) violate,
              misappropriate, or infringe the rights of Marco Management, our
              customers, or others, including privacy, publicity, intellectual
              property, or other proprietary rights; (b) are illegal, obscene,
              defamatory, threatening, intimidating, harassing, hateful,
              racially or ethnically offensive, or instigate or encourage
              conduct that would be illegal or otherwise inappropriate, such as
              promoting violent crimes, endangering or exploiting children or
              others, or coordinating harm; (c) involve submitting
              misrepresentations, or misleading statements through the Site; (d)
              impersonate another person or customer; (e) involve sending
              illegal or impermissible communications such as bulk messaging,
              auto-messaging, auto-dialing, and the like; (f) involve any
              illegal or fraudulent conduct, act, or omission; (g) make any
              statement that you are an agent of Marco Management; (h) contains
              any solicitation, promotion, political campaign, advertising, or
              other communication not expressly approved by Marco Management; or
              (i) in our sole and absolute discretion, is objectionable or may
              result in any of Marco Management's employees or other users to
              any harm or liability.
            </p>
            <p>
              If you are agreeing to these Terms and Conditions of Use on behalf
              of any other organization, entity, or co-applicant, you represent
              and warrant that you are duly authorized to agree to these Terms
              and Conditions of Use on behalf of that organization, entity, or
              co-applicant and bind them to these Terms and Conditions of Use
              (in which case, the references to "you" and "your" in these Terms
              and Conditions of Use, except for in this sentence, refer to that
              organization, entity, or co-applicant). If we have previously
              prohibited you from accessing the Site and the related Content,
              you do not have permission to access the Site and related Content.
            </p>
            <h3>
              <strong>Phone Number Collection and Communication</strong>
            </h3>
            <div className="mb-2">
              We collect phone numbers as a part of our data collection process,
              and we utilize texting and calling as the form of communication.
              Your phone number may be used for various communication purposes,
              such as sending important updates, promotions, account
              verification, or providing customer support.
            </div>
            <ul>
              <li className="mb-2">
                <strong style={{ display: "block" }}>
                  How to Opt-Out from Text Messages:{" "}
                </strong>
                You can cancel the SMS service at any time by replying{" "}
                <strong>'STOP'</strong> to the number from which you receive
                texts. Upon sending the message <strong>'STOP'</strong>, we will
                confirm your unsubscribe request, and you will no longer receive
                SMS messages from us. If you want to rejoin, simply reach out to
                us at{" "}
                <a href="tel:+18669244732" target="_blank" rel="noreferrer">
                  (866) 924-4732
                </a>
                .
              </li>{" "}
              <li>
                <strong style={{ display: "block" }}>
                  How to Opt-Out from Calls/Voicemails:{" "}
                </strong>
                To opt out of our calls, just send a message with the word{" "}
                <strong>'STOP'</strong> to the same number from which you
                receive calls. We will acknowledge your request, and you will no
                longer receive our calls. If you want to rejoin, simply reach
                out to us at <a href="tel:+18669244732">(866) 924-4732</a>.
              </li>{" "}
            </ul>{" "}
            <h3>
              <strong>LIMITATION OF LIABILITY</strong>
            </h3>
            <p>
              In no event shall Marco Management or its affiliates, licensors,
              employees, agents or contractors be liable to you for any damages
              or losses, including but not limited to, direct, indirect,
              special, consequential, incidental or punitive damages resulting
              from or caused by the Site any Content on the Site, its public
              posting web boards, its content or any errors or omissions in its
              content, even if advised of possibility of such damages.
            </p>
            <p>
              Marco Management WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR
              CONSEQUENTIAL, SPECIAL, PUNITIVE, INDIRECT, OR INCIDENTAL DAMAGES
              RELATING TO, ARISING OUT OF, OR IN ANY WAY IN CONNECTION WITH OUR
              TERMS AND CONDITIONS OF USE, OUR USE OF THE DATA YOU SUPPLY TO US
              IN CONNECTION WITH YOUR USE OF THE SITE, YOUR USE OF THE SITE OR
              THE CONTENT (HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY,
              INCLUDING NEGLIGENCE), EVEN IF Marco Management HAVE BEEN ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGES. THE LAWS OF SOME STATES OR
              JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
              DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS SET
              FORTH ABOVE MAY NOT APPLY TO YOU. NOTWITHSTANDING ANYTHING TO THE
              CONTRARY IN OUR TERMS AND CONDITIONS OF USE, IN SUCH CASES, THE
              LIABILITY OF Marco Management WILL BE LIMITED TO THE FULLEST
              EXTENT PERMITTED BY APPLICABLE LAW.
            </p>
            <h3>
              <strong>INDEMNIFICATION</strong>
            </h3>
            <p>
              You agree to indemnify, defend and hold harmless Marco Management,
              its officers, directors, employees, agents and third parties, for
              any losses, costs, liabilities, and expenses (including reasonable
              attorneys’ fees) relating to or arising out of your use of or
              inability to use the Site, Content, or services, any violation of
              any of the Terms and Conditions of Use, or your violation of any
              rights of a third party, or your violation of any applicable laws,
              rules, or regulations.
            </p>
            <h3>
              <strong>HYPERLINKS TO THIRD PARTY SITES</strong>
            </h3>
            <p>
              As a service to users of the Site, we include links to other sites
              ("Linked Sites") and Marco Management has no control over and
              makes no representations of any kind regarding the content or
              appropriateness of content on such Linked Sites, and you hereby
              irrevocably waive any claim against Marco Management with respect
              to such Linked Sites.
            </p>
            <p>
              The Linked Sites are not under the control of Marco Management and
              Marco Management is not responsible for the content of any Linked
              Site, including, without limitation, any link contained on a
              Linked Site, or any changes or updates to a Linked Site. Marco
              Management is providing these links to you only as a convenience,
              and the inclusion of any link does not imply endorsement by Marco
              Management or responsibility for the opinions, ideas, products,
              information, security, privacy, or services offered at such Linked
              Sites, or any representation regarding the content on such Linked
              Sites.
            </p>
            <p>
              Certain services made available on the Site or by Marco Management
              are delivered by third-party sites and organizations. By using any
              product, service, or functionality originating from the Site or
              through Marco Management, you hereby acknowledge and consent that
              Marco Management may share such information and data with any
              third party with whom Marco Management has a contractual
              relationship to provide the requested product, service, or
              functionality on behalf of you.
            </p>
            <h3>
              <strong>SUBMISSIONS</strong>
            </h3>
            <p>
              You acknowledge that by submitting messages or materials,
              including but not limited to, posting or email, to Marco
              Management, your submissions will be recorded and you expressly
              grant Marco Management a perpetual, royalty-free, irrevocable,
              non-exclusive right and license to use, reproduce, modify, adapt,
              publish, translate, create derivative works from and distribute
              such materials or incorporate such materials into any form.
            </p>
            <h3>
              <strong>TERMINATION</strong>
            </h3>
            <p>
              Marco Management may, in its sole discretion, terminate, cancel or
              suspend your right to access to all or part of the service at the
              Site for any reason, at any time without notice. Upon termination,
              you must destroy all materials obtained from the site, and all
              copies thereof, whether made under this TERMS AND CONDITIONS OF
              USE or otherwise.
            </p>
            <h3>
              <strong>DISPUTE RESOLUTION</strong>
            </h3>
            <p>
              PLEASE READ THIS CAREFULLY. IT AFFECTS YOUR RIGHTS. YOU AGREE THAT
              BY USING THIS SITE AND THE CONTENT, OR BY OTHERWISE AGREEING TO
              THESE TERMS AND CONDITIONS OF USE, YOU AND Marco Management ARE
              EACH WAIVING THE RIGHT TO A COURT OR JURY TRIAL OR TO PARTICIPATE
              IN A CLASS ACTION. YOU AND MARCO MANAGEMENT AGREE THAT YOU MAY
              BRING CLAIMS AGAINST MARCO MANAGEMENT ONLY IN YOUR INDIVIDUAL
              CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
              CLASS, REPRESENTATIVE, OR COLLECTIVE PROCEEDING. ANY ARBITRATION
              WILL TAKE PLACE ON AN INDIVIDUAL BASIS; YOU AND WE AGREE THAT
              CLASS ARBITRATIONS, CLASS ACTIONS, OR OTHER COMBINED PROCEEDINGS
              ARE NOT PERMITTED.
            </p>
            <p>
              YOU AND MARCO MANAGEMENT AGREE TO SUBMIT ALL DISPUTES TO BINDING
              INDIVIDUAL ARBITRATION, EXCEPT FOR THOSE THAT INVOLVE INTELLECTUAL
              PROPERTY DISPUTES AND EXCEPT THOSE THAT CAN BE BROUGHT IN SMALL
              CLAIMS COURT. THIS MEANS YOU WAIVE YOUR RIGHT TO HAVE SUCH
              DISPUTES RESOLVED IN COURT BY A JUDGE OR JURY. FINALLY, YOU MAY
              BRING A CLAIM ONLY ON YOUR OWN BEHALF, AND NOT ON BEHALF OF ANY
              OFFICIAL OR OTHER PERSON, OR CLASS OF PEOPLE. YOU WAIVE YOUR RIGHT
              TO PARTICIPATE IN, OR HAVE YOUR DISPUTE HEARD AND RESOLVED AS, A
              CLASS ACTION, A CLASS ARBITRATION, OR A REPRESENTATIVE ACTION.
            </p>
            <p>
              You agree that any claim or cause of action you have against Marco
              Management relating to, arising out of, or in any way in
              connection with our Site or the Content that is not governed by
              the arbitration clause above, and for any claim or cause of action
              that Marco Management files against you, you and Marco Management
              agree that any such claim or cause of action (each, a "Dispute",
              and together, "Disputes") will be resolved exclusively in the
              United States District Court for the Southern District of
              California or a state court located in Los Angeles County in
              California, and you agree to submit to the personal jurisdiction
              of such courts for the purpose of litigating any such claim or
              cause of action, and the laws of the State of California will
              govern any such claim or cause of action without regard to
              conflict of law provisions. Without prejudice to the foregoing,
              you agree that, in our sole discretion, we may elect to resolve
              any Dispute we have with you that is not subject to arbitration in
              any competent court in the country in which you reside that has
              jurisdiction over the Dispute.
            </p>
            <p>
              The laws of the State of California govern our Terms and
              Conditions of Use, as well as any Disputes, whether in court or
              arbitration, which might arise between Marco Management and you,
              without regard to conflict of law provisions.
            </p>
            <p>
              THESE TERMS AND CONDITIONS OF USE ALSO LIMIT THE TIME YOU HAVE TO
              BRING A CLAIM OR DISPUTE, INCLUDING THE TIME TO START AN
              ARBITRATION OR, IF PERMISSIBLE, A COURT ACTION OR SMALL CLAIMS
              PROCEEDING TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
              Marco Management and you agree that for any Dispute (except for
              the Excluded Disputes defined below) we and you must bring Claims
              (including commencing an arbitration proceeding) within one year
              after the Dispute first arose; otherwise, such Dispute is
              permanently barred. This means that if Marco Management or you do
              not bring a Claim (including commencing an arbitration) within one
              year after the Dispute first arose, then the arbitration will be
              dismissed because it was started too late.
            </p>
            <p>
              "Excluded Dispute" means any Dispute relating to the enforcement
              or infringement of your or our intellectual property rights (such
              as copyrights, trademarks, domains, logos, trade dress, trade
              secrets, and patents) or efforts to interfere with the Site or
              engage with our Site in unauthorized ways (for example, automated
              ways). For clarity and notwithstanding the foregoing, those
              Disputes relating to, arising out of, or in any way in connection
              with your rights of privacy and publicity are not Excluded
              Disputes.
            </p>
            <p>
              Before you commence arbitration of a Dispute, you must provide us
              with a written Notice of Dispute that includes as applicable your
              (a) name; (b) residence address; (c) username; (d) email address
              or phone number you use to contact Marco Management; (e) a
              detailed description of the dispute; and (f) the relief you seek.
              Any Notice of Dispute you send to us should be mailed to Marco
              Management, LLC. ATTN: Marco Management Arbitration Filing, 18303
              Gridley Rd., Cerritos, CA 90703 Attn: General Counsel. Before we
              commence arbitration, we will send you a Notice of Dispute to the
              email address you provide, or other appropriate means. If we are
              unable to resolve a dispute within sixty (60) days after the
              Notice of Dispute is received, you or we may commence arbitration.
            </p>
            <p>
              The arbitration will be administered by the American Arbitration
              Association ("AAA") under its Commercial Arbitration Rules in
              effect at the time the arbitration is started, including the
              Optional Rules for Emergency Measures of Protection and the
              Supplementary Procedures for Consumer-Related Disputes (together,
              the "AAA Rules"). The arbitration will be presided over by a
              single arbitrator selected in accordance with the AAA Rules. The
              AAA Rules, information regarding initiating a Dispute, and a
              description of the arbitration process are available at
              www.adr.org. Issues relating to the scope and enforceability of
              the arbitration provision are for a court to decide. The location
              of the arbitration and the allocation of fees and costs for such
              arbitration shall be determined in accordance with the AAA Rules.
            </p>
            <p>Marco Management, LLC.</p>
            <h3>
              <strong>Arbitration Opt-Out</strong>
            </h3>
            <p>
              18303 GRIDLEY RD, CERRITOS, CA 90703
              <br />
              Attn: General Counsel
            </p>
            <p>
              You must include: (i) your name and residence address; (ii) the
              mobile phone number associated with your account; and (iii) a
              clear statement that you want to opt out of our Terms and
              Conditions of Use agreement to arbitrate.
            </p>
            <p>
              Marco Management and you each agree that if you are located in the
              United States, each of Marco Management, LLC. and the user may
              bring Disputes against the other only on its or your own behalf,
              and not on behalf of any other person or entity, or any class of
              people. We and you each agree not to participate in a class
              action, a class-wide arbitration, Disputes brought in a private
              attorney general or representative capacity, or consolidated
              Disputes involving any other person or entity in connection with
              any Dispute. If there is a final judicial determination that any
              particular Dispute (or a request for particular relief) cannot be
              arbitrated in accordance with this provision's limitations, then
              only that Dispute (or only that request for relief) may be brought
              in court. All other Disputes (or requests for relief) remain
              subject to this provision.
            </p>
            <p>
              Place To File Permitted Court Actions. If you opt out of the
              agreement to arbitrate, if your Dispute is an Excluded Dispute, or
              if the arbitration agreement is found to be unenforceable, you
              agree to be subject to the applicable provision in the "Dispute
              Resolution" section set forth above.
            </p>
            <p>
              Marco Management, LLC. expressly reserves the right to expand,
              add, or remove our Content, features, functionalities, and the
              support of certain devices and platforms from the Site. Our Site
              and Content may be interrupted, including for maintenance,
              repairs, upgrades, or network or equipment failures. We may
              discontinue some or all of our Site and Content, including certain
              features and the support for certain devices and platforms, at any
              time. Events beyond our control may affect our Site and Content,
              such as events in nature and other force majeure events.
            </p>
            <h3>
              <strong>MISCELLANEOUS</strong>
            </h3>
            <p>
              1. Marco Management reserves the right to change, remove or modify
              any and all content contained on the Site at any time without
              notice. 2. You agree to report any copyright violations of the
              TERMS AND CONDITIONS OF USE to Marco Management, LLC. as soon as
              you become aware of them. In the event you have a claim of
              copyright infringement with respect to material that is contained
              in the Site, please notify us. (Contact us)
            </p>
            <h3>
              <strong>AUTHORITY</strong>
            </h3>
            <p>
              The only persons authorized to enter into contracts on behalf of
              Marco Management are the company's President and Chief Executive
              Officer. You may call our corporate office at{" "}
              <a href="tel:+18669244732">(866) 924-4732</a> or e-mail{" "}
              <a href="mailto:info@forrentca.com">info@forrentca.com</a> to
              verify the execution of any contract. No employee of Marco
              Management has the authority to make any amendment to or waiver
              proposed by you of our Terms and Conditions of Use and may only be
              made by Marco Management's President or General Counsel in
              writing.
            </p>
            <h3>
              <strong>RECORDING AND COMMUNICATION</strong>
            </h3>
            <p>
              Phone calls between you and any representatives of Marco
              Management, LLC. may be recorded and monitored for quality
              assurance and training purposes. You shall not be denied access to
              any services nor will you be subject to additional charges if you
              request the Marco Management telephone agent to disable recording
              and monitoring. Pursuant to these Terms and Conditions of Use, you
              agree to receive text messages and phone calls (from Marco
              Management, LLC. or our third-party providers) in connection with
              your use of the Site and the services provided by Marco
              Management, LLC. through the Content.
            </p>
            <h3>
              <strong>YouTube's Term of Service:</strong>
            </h3>
            <p>
              Our website may include embedded YouTube videos or links to
              YouTube content. By using our website, you acknowledge that
              YouTube's Terms of Service and Community Guidelines also apply to
              your use of YouTube content here.
            </p>
            <p>
              Please go through YouTube's Terms of Service at{" "}
              <a
                href="https://www.youtube.com/t/terms"
                target="_blank"
                rel="noreferrer"
              >
                https://www.youtube.com/t/terms
              </a>{" "}
              for more details.
            </p>
            <h3>
              <strong>Google Privacy Policy:</strong>
            </h3>
            <p>
              Please go through Google Privacy Policy at{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                https://policies.google.com/privacy
              </a>{" "}
              for more details.
            </p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
