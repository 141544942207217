import React from "react";
import { Container, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../css/footer.css";
import EOH from "../images/eoh.png";

function Footer() {
  return (
    <>
      <hr className="fixated"></hr>
      <Container className="text-center" fluid={true}>
        <p>*All information deemed reliable but not guaranteed*</p>
        <p className="flexBox">
          <Nav.Link as={Link} to="/">
            Home
          </Nav.Link>{" "}
          |{" "}
          <Nav.Link as={Link} to="/About">
            About Us
          </Nav.Link>{" "}
          |{" "}
          <Nav.Link as={Link} to="/Contact">
            Contact Us
          </Nav.Link>{" "}
          |{" "}
          <Nav.Link as={Link} to="/privacy-policy">
            Privacy Policy
          </Nav.Link>{" "}
          |{" "}
          <Nav.Link as={Link} to="/terms-and-conditions">
            Terms and Conditions
          </Nav.Link>
        </p>

        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center justify-content-md-between align-items-md-center footer-content">
          <div className="ml-0 ml-lg-5">
            <Image
              className="ml-0 ml-lg-5"
              src={process.env.REACT_APP_THUMBNAIL_IMAGE}
            />
          </div>

          <div
            className="d-flex flex-column align-items-start justify-content-center my-3 my-md-0"
            style={{ width: "fit-content" }}
          >
            <div>
              <strong>Contact Us:</strong>
            </div>
            <div className='text-left'>
              Address:{" "}
              <a href="https://goo.gl/maps/vmWafJ6pieBNEJ357" target="_blank" rel='noreferrer'>
                18303 Gridley Rd Cerritos, CA 90703
              </a>
            </div>
            <div className='text-left'>
              Call: <a href="tel:+18669244732">(866) 924-4732</a>
            </div>
            <div className='text-left'>
              Email: <a href="mailto:info@forrentca.com">info@forrentca.com</a>
            </div>
          </div>
        </div>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.hud.gov/fairhousing"
        >
          <Image
            fluid
            src={EOH}
            thumbnail
            className='mb-3'
            style={{ height: "75px", width: "75px" }}
          />
        </a>
      </Container>
    </>
  );
}

export default Footer;
